/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
export const onClientEntry = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
                registration.unregister().then(() => {
                    console.log('Service worker unregistered:', registration.scope);
                });
            }
        }).catch((err) => {
            console.error('Service worker unregistration failed:', err);
        });
    }
};

